<div class="row no-gutters recover-password-page">
  <div class="col-md-6 col-sm-12 form-section">
    <h1 class="page-title">Dont worry!</h1>
    <span class="page-subtitle">
      We are here to help you recovering your password. Please enter the email address you used when you joined and we will send you an email with the instructions to reset your password.
    </span>
    <form class="recover-password-form" [formGroup]="recoverPasswordForm" (ngSubmit)="onRecoverPassword()">
      <div class="form-group">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" formControlName="email">
      </div>
      <div class="form-call-to-actions row no-gutters">
        <a class="col-xs-12 col-sm-6 form-action text-link" [routerLink]="['/authentication/signin']">Go back to Login page</a>
        <button class="col-xs-12 col-sm-6 form-action recover-password-btn" type="submit">Recover Password</button>
      </div>
    </form>
  </div>
  <div  class="col-md-6 d-none d-md-block image-section">
    <cc-image-shell class="add-overlay recover-password-image" animation="spinner" [mode]="'cover'" [src]="'/assets/imgs/authentication/recover-password-image.jpg'" [alt]="'app image'"></cc-image-shell>
  </div>
</div>
