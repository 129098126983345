<ng-content></ng-content>
<a class="type-toggle" (click)="toggleShow()">
	<i class="fas fa-eye show-option" [hidden]="show"></i>
	<i class="fas fa-eye-slash hide-option" [hidden]="!show"></i>
  <!-- In case you want to use text instead of icons -->
	<!--
  <span class="show-option" [hidden]="show">show</span>
	<span class="hide-option" [hidden]="!show">hide</span>
  -->
</a>
